const companySize = {
  en: {
    translation: {
      L: "Large enterprise",
      M: "Medium enterprise",
      U: "Micro enterprise",
      S: "Small enterprise",
      P: "Pubblic administration",
    },
  },
  it: {
    translation: {
      L: "Grande impresa",
      M: "Media impresa",
      U: "Micro impresa",
      S: "Piccola impresa",
      P: "Pubblica amministrazione",
    },
  },
};

export default companySize;
