const packages = {
  en: {
    translation: {
      codes: "Codes",
      date: "Date",
      status: "Status",
      services: "Services",
      credentials_limit: "Credentials limit",
      duration: "duration",
      years: "years",
      months: "months",
      days: "days",
      services_packages: "Services packages",
      add_package: "Add package",
      no_packages_yet: "There are no packages yet",
      name: "Name",
      description: "Description",
      limit: "Limit",
      package_added_title: "Package added",
      package_added_text: "Service package added succesfully",
      package_added_error_title: "Error adding packaged",
      package_added_error_text:
        "Ops! It seems there was an error while creating the new service package! Please, try again later",
    },
  },
  it: {
    translation: {
      codes: "Codici",
      date: "Data",
      status: "Stato",
      services: "Servizi",
      credentials_limit: "Limite credenziali",
      duration: "durata",
      years: "anni",
      months: "mesi",
      days: "giorni",
      services_packages: "Pacchetti di servizi",
      add_package: "Aggiungi pacchetto",
      no_packages_yet: "Non ci sono ancora pacchetti",
      name: "Nome",
      description: "Descrizione",
      limit: "Limite",
      package_added_title: "Pacchetto aggiunto",
      package_added_text: "Hai aggiunto il nuovo pacchetto di servizi con successo",
      package_added_error_title: "Errore",
      package_added_error_text:
        "Ops! Sembra che ci sia stato un errore durante la creazione del pacchetto di servizi! Per favore, riprova più tardi",
    },
  },
};

export default packages;
