/* eslint-disable prettier/prettier */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Datetime from "react-datetime";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
// import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";

function addServiceDetails({ availableServices, addElement }) {
  const [expiration, setExpiration] = useState(null);
  const [service, setService] = useState(null);

const { t } = useTranslation();

function getSelect(options, editFunction, defaultValue) {
    return (
      <select
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        defaultValue={defaultValue}
        className="selectDefault"
        style={{
          width: "200px",
          padding: "0.75rem",
          borderRadius: "0.375rem",
          borderColor: "#d2d6da",
          marginRight: "15px",
        }}
      >
        {options.map((el, index) => (
          <option value={el.code} key={`option-role-${index.toString()}`}>
            {el.description}
          </option>
        ))}
      </select>
    );
  }

function getCalendar(value, changeFunction, label) {
    return (
        <div>
        {/* {value ? (
            <MDTypography fontSize="small" mb={1} textAlign="left">
            {label}
            </MDTypography>
        ) : (
            ""
        )} */}
        <div style={{ position: "relative", marginRight: "10px" }}>
            <Datetime
            inputProps={{
                placeholder: label,
                value: value ? moment(value).format("DD/MM/YYYY") : "",
            }}
            closeOnSelect
            timeFormat={false}
            value={value ? moment(value).format("DD/MM/YYYY") : ""}
            onChange={(e) => {
                changeFunction(moment(e).format("L"));
            }}
            />
            {value ? (
            <IconButton
                onClick={() => {
                changeFunction("");
                }}
                style={{ position: "absolute", right: "0", top: "6px" }}
                size="small"
                disableRipple
            >
                <Icon fontSize="medium">close</Icon>
            </IconButton>
            ) : null}
        </div>
        </div>
    );
  }

  return (
    <div className="addServiceDetails-container" style={{ display: "flex", alignItems: "center" }}>
      {getSelect(availableServices, setService, service)}
      {getCalendar(expiration, setExpiration, t("admin.expiration"))}
      <IconButton
          onClick={() => {
            addElement({ code: service, expiresOn: expiration });
          }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">add</Icon>
        </IconButton>
    </div>
  );
}

// Typechecking props for the addServiceDetails
addServiceDetails.propTypes = {
  availableServices: PropTypes.arrayOf(PropTypes.any).isRequired,
  addElement: PropTypes.func.isRequired,
};

addServiceDetails.defaultProps = {
  label: "",
};

export default addServiceDetails;
