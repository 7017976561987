import config from "config/config";
import getTokens from "api/getTokens";

const { baseUrl, clientUrl } = config;

export default async function editUser(user, id) {
  const endpoint = `${baseUrl}/v1/users/${id}`;
  let access = "";
  const tokens = getTokens();

  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
    return;
  }

  const options = {
    method: "PATCH",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl || ""}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify(user),
  };

  try {
    const response = await fetch(endpoint, options);

    if (!response.ok) {
      // Get error message
      const errorData = await response.json();
      const errorMessage = errorData.message || "Errore sconosciuto";
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error("Edit user error:", error.message);
    throw error;
  }
}
