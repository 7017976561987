import config from "config/config";
import getTokens from "api/getTokens";
import { handleOrderBy } from "./common";

const { baseUrl, clientUrl } = config;

/* *********************
  email
  password
********************** */

export default function getStealersBySites(
  sites,
  page,
  limit = 50,
  nation = "",
  domain = "",
  reason = "",
  hash = "",
  tags = "",
  showHidden = false,
  dateFrom = "",
  dateTo = "",
  orderBy = ""
) {
  const order = handleOrderBy(orderBy);
  const endpoint = `${baseUrl}/v1/stealers/bySites/?limit=${limit}&page=${page}&sortBy=${order}&nation=${nation}&domain=${domain}&reason=${reason}&tag=${tags}&hash=${hash}&showHidden=${showHidden}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }
  const domains = { sites };
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl || ""}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
    body: JSON.stringify(domains),
  };
  // console.log("login endpoint", { endpoint });
  return fetch(endpoint, options)
    .then((response) => {
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response.json();
    })
    .catch((e) => {
      // eslint-disable-next-line
      console.log("error", e);
    });
}
