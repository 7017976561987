/* eslint-disable prettier/prettier */
// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import getAvailableServices from "api/servicepackages/getAvailableServices";
import createServicePackage from "api/servicepackages/createServicePackage";
import getServicesPackages from "api/servicepackages/getServicesPackages";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import IconButton from "@mui/material/IconButton";
import Pagination from "layouts/dashboards/stealers/pagination";
import PackageAddition from "layouts/backoffice/servicesPackages/packageAddition";
import SweetAlert from "react-bootstrap-sweetalert";

function Codes() {
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [servicesPackages, setServicesPackages] = useState([]);
  const [addingPackage, setAddingPackage] = useState(false);
  const [addedPopup, setAddedPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [serverError, setServerError] = useState({
    ransomwaresCounters: false,
    ransomwaresTable: false,
  });
  const [availableServices, setAvailableServices] = useState([]);
  const { t } = useTranslation();

  function setRequest(res) {
    if (res.results && res.results.length > 0) {
      setServicesPackages(res.results);
    }
    if (res && res.length > 0) {
      setServicesPackages(res);
    } else if (res.length === 0 || (res.results && res.results.length === 0)) {
      setServicesPackages([]);
    }
  }

  function refreshServicesPackages() {
    getServicesPackages(page, limit)
      .then((res) => {
        setPages(res.totalPages);
        setRequest(res);
      })
      .catch((err) => {
        setServerError({ ...serverError, ransomwaresTable: true });
        // eslint-disable-next-line no-console
        console.log("error", err);
      });
  }

  useEffect(() => {
    getAvailableServices()
      .then((res) => setAvailableServices(res))
      .catch((err) => {
        setServerError({ ...serverError });
        // eslint-disable-next-line no-console
        console.log("error", err);
      });
  }, []);

  useEffect(() => {
    refreshServicesPackages();
  }, [page, limit]);

  function getServices(services) {
    return services.map(el => el.code).join(", ");
  }

  function getDuration(services) {
    let duration = "";
    if (services.length > 0 && services[0] && services[0].duration) {
      const dur = services[0].duration;
      if (dur.years) {
        duration = `${dur.years.toString()}Y`;
        if (dur.months || dur.days) {
          duration += ", ";
        }
      }
      if (dur.months) {
        duration += `${dur.months.toString()}M`;
        if (dur.days) {
          duration += ", ";
        }
      }
      if (dur.days) {
        duration += `${dur.days.toString()}D`;
      }
    }
    return duration;
  }

  function addServicePackage(newService) {
    createServicePackage(newService)
      .then((res) => {
        if (res && res.status >= 200 && res.status < 300) {
          setAddedPopup(true)
          setAddingPackage(false);
          refreshServicesPackages();
        } else {
          setErrorPopup(true);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error while adding new service package", err);
        setErrorPopup(true);
      })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2 className="page-title" style={{ marginBottom: "50px" }}>
        {t("packages.services_packages")}
      </h2>
      <div style={{ marginBottom: "50px" }}>
        <IconButton
          onClick={() => {
            setAddingPackage(true);
          }}
          className="default-button"
          size="small"
          disableRipple
        >
          {t("packages.add_package")}
        </IconButton>
      </div>
      {addingPackage ? (
        <PackageAddition closeFunction={() => { setAddingPackage(false) }} availableServices={availableServices} addElement={addServicePackage} />
      ) : null}
      {addedPopup ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("packages.package_added_title")}
          onConfirm={() => {
            setAddedPopup(false);
          }}
          confirmBtnText="Ok"
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("packages.package_added_text")}
        </SweetAlert>
      ) : null}
      {errorPopup ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("packages.package_added_error_title")}
          onConfirm={() => {
            setErrorPopup(false);
          }}
          error
          confirmBtnText="Ok"
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("packages.package_added_error_text")}
        </SweetAlert>
      ) : null}
      {servicesPackages && servicesPackages.length > 0 ? (
        <div className="table_users_list">
          <table className="table-content" cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>{t("packages.date")}</th>
                <th style={{ textAlign: "center" }}>{t("packages.name")}</th>
                <th style={{ textAlign: "center" }}>{t("packages.services")}</th>
                <th style={{ textAlign: "center" }}>{t("packages.duration")}</th>
              </tr>
            </thead>
            <tbody>
              {servicesPackages.map((servicePackage, index) => (
                <tr key={index.toString()} style={{ textAlign: "center" }}>
                  <td>{servicePackage.createdAt ? moment(servicePackage.createdAt).format("DD/MM/YYYY") : null}</td>
                  <td>{servicePackage.name || ""}</td>
                  <td>
                    {servicePackage.services && servicePackage.services.length > 0 ? getServices(servicePackage.services) : ""}
                  </td>
                  <td>{getDuration(servicePackage?.services || "")}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
        </div>
      ) : (
        <div>{t("packages.no_packages_yet")}</div>
      )}
    </DashboardLayout>
  );
}

export default Codes;
