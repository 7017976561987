import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";
import getNotificationsByCustomers from "api/notification/getNotificationsByCustomers";
import Pagination from "layouts/dashboards/stealers/pagination";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";

// @mui material Icons
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LinkIcon from "@mui/icons-material/Link";
import ServerError from "utilities/ServerError";

function AlertsPage() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user, customers } = controller;
  const [notifications, setNotifications] = useState([]);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [limit, setLimit] = useState(50);
  const [serverError, setServerError] = useState(false);
  const { t } = useTranslation();

  function refreshNotifications() {
    if (user && customers && customers.length > 0) {
      getNotificationsByCustomers(page, limit)
        .then((res) => {
          if (res.totalPages) {
            setPages(res.totalPages);
          }
          if (res && res.results && res.results.length > 0) {
            setNotifications(res.results);
            if (
              window?.location?.href &&
              window.location.href.includes("#") &&
              window.location.href.split("#") &&
              window.location.href.split("#").length > 1
            ) {
              const notId = window.location.href.split("#")[1];
              console.log(notId);
              console.log(`notification-${notId}`);
              if (document.getElementById(`notification-${notId}`)) {
                document.getElementById(`notification-${notId}`).scrollIntoView({
                  behavior: "smooth",
                });
              }
            }
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log("error while getting notifications", err);
          setServerError(true);
        });
      // setNotifications(
      //   [
      //     {
      //       customer: "greenenergybank.it",
      //       date: "2022-11-22T11:15:52.181Z",
      //       id: "637caf68c325b3474639807e",
      //       stealer: "637caf3724d3ce0652f28990",
      //       type: "stealer_cookie_found",
      //     },
      //     {
      //       customer: "greenenergybank.it",
      //       date: "2022-11-22T11:15:52.181Z",
      //       id: "637caf68c325b3474639807d",
      //       stealer: "637caf3724d3ce0652f28990",
      //       type: "stealer_cookie_found",
      //     },
      //     {
      //       customer: "greenenergybank.it",
      //       date: "2022-11-22T11:15:52.178Z",
      //       id: "637caf68c325b3474639807b",
      //       stealer: "637caf3724d3ce0652f28990",
      //       type: "stealer_password_found",
      //     },
      //   ]
      // );
    }
  }

  useEffect(() => {
    // console.log("customers", customers);
    refreshNotifications();
  }, [user, customers, page, limit]);

  function checkNumber(type) {
    const firstChar = type.charAt(0);
    if (Number(firstChar)) {
      const arr = type.split("-");
      return arr[1];
    }
    return type;
  }

  function getTitle(type) {
    return t(`analytics.title_${checkNumber(type)}`);
  }

  function getText(type) {
    if (type.includes("-")) {
      return `${type.split("-")[0]} ${t(`analytics.text_${type.split("-")[1]}`)}`;
    }
    return t(`analytics.text_${checkNumber(type)}`);
  }

  function getColor(type) {
    const str = type.replace(/[0-9]/g, "");
    if (str === "-credentials_found" || str === "ransomware") {
      return "#CF1A1A";
    }
    return "#0BC0A0";
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2>{t("alerts.title")}</h2>
      {!serverError ? (
        <MDBox className="boxes-border-radius" padding={2.3} sx={{ background: "white" }}>
          {notifications.length > 0 ? (
            notifications.map((item) => (
              <div
                id={`notification-${item._id.toString()}`}
                key={`notification-${item._id.toString()}`}
              >
                <MDBox mt={2}>
                  <MDTypography
                    mb={1}
                    fontSize="large"
                    fontWeight="bold"
                    sx={{ color: getColor(item.type) }}
                  >
                    {getTitle(item.type)}
                  </MDTypography>
                  <MDTypography mb={1} fontSize="medium">
                    {getText(item.type)}
                  </MDTypography>
                  {item.stealers &&
                  item.stealers.length > 0 &&
                  item.type &&
                  item.type.includes("credentials_found") ? (
                    <MDTypography mb={1} fontSize="medium" className="notification-links-container">
                      {t(`alerts.elements_involved`)}
                      {item.stealers.map((st, index) => (
                        <span
                          key={`stealer-in-notification-${item._id.toString()}-${st.toString()}`}
                        >
                          <IconButton
                            onClick={() => {
                              window.open(`/identity`, "_blank").focus();
                            }}
                            className="notification-link"
                            size="small"
                            disableRipple
                          >
                            {(index + 1).toString()}
                          </IconButton>
                        </span>
                      ))}
                    </MDTypography>
                  ) : null}
                  <MDBox display="flex" alignItems="center">
                    <CalendarTodayIcon />
                    <MDTypography
                      variant="button"
                      color="dark"
                      fontSize="small"
                      fontWeight="light"
                      ml={1}
                    >
                      {moment(item.date).format("DD/MM/YYYY - HH:mm UTCZ")}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <Divider sx={{ border: "1px solid lightgrey", marginTop: "17px" }} />
              </div>
            ))
          ) : (
            <h5>{t("analytics.no_alerts")}</h5>
          )}
        </MDBox>
      ) : (
        <ServerError />
      )}
      {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
    </DashboardLayout>
  );
}

export default AlertsPage;
