import IntercomComponent from "components/Intercom";
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Help = () => {
  const { t } = useTranslation();

  // De-comment the lines below in order to use the "user" variable to check if is admin or not
  const [controller] = useMaterialUIController();
  const { user, clients } = controller;

  useEffect(() => {}, [user]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2 className="page-title" style={{ marginBottom: "50px" }}>
        {t("sidenav.help")}
      </h2>
      <>
        <div className="reports-page-description-box">
          <p style={{ fontWeight: "600" }}>{t("help.most_frequent")}</p>
          <p style={{ fontSize: "small" }}>...</p>
        </div>
        <IntercomComponent user={user} pro={clients?.some((e) => e?.serviceDetail?.length > 0)} />
      </>
    </DashboardLayout>
  );
};

export default Help;
