const help = {
  en: {
    translation: {
      most_frequent: "Most frequent topics",
    },
  },
  it: {
    translation: {
      most_frequent: "Argomenti più frequenti",
    },
  },
};

export default help;
