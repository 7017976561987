/* eslint-disable prettier/prettier */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState /* , useEffect */ } from "react";
import { useTranslation } from "react-i18next";
// import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import SweetAlert from "react-bootstrap-sweetalert";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

function packageAddition({ availableServices, addElement, closeFunction }) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [packageServices, setPackageServices] = useState([]);
    const [duration, setDuration] = useState({ years: 0, months: 1, days: 0 });
    const [credentialsLimit, setCredentialsLimit] = useState([]);

const { t } = useTranslation();

function getInput(type, editFunction, defaultValue) {
    return (
      <MDInput
        type="input"
        label={type}
        fullWidth
        defaultValue={defaultValue}
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        style={{ marginBottom: "20px", marginTop: "20px" }}
      />
    );
  }

  function getServicesSelection() {
    return (
      <div className="services-selection-container" style={{ flexWrap: "wrap" }}>
        {availableServices.map((ser, index) => (
          <div
            key={`${ser.code}-${index.toString()}`}
            className="service-selection"
            style={{ marginBottom: "10px" }}
          >
            <IconButton
              onClick={() => {
                const newPackageServices = [].concat(packageServices);
                if (newPackageServices.includes(ser.code)) {
                  const indexToRemove = newPackageServices.indexOf(ser.code);
                  newPackageServices.splice(indexToRemove, 1);
                } else {
                  newPackageServices.push(ser.code);
                  if (ser.associatedCredentials && ser.associatedCredentials.length > 0) {
                    const newCredentialsLimit = [].concat(credentialsLimit);
                    const newCredentialsLimitToAdd = [].concat(ser.associatedCredentials);
                    newCredentialsLimitToAdd.forEach(cr => {
                      if (!credentialsLimit.some(el => el.type === cr)) {
                        newCredentialsLimit.push({ type: cr, limit: 5 });
                      }
                    });
                    setCredentialsLimit(newCredentialsLimit);
                  }
                }
                setPackageServices(newPackageServices);
              }}
              className={`service-to-select ${
                packageServices.includes(ser.code) ? "is-selected" : ""
              }`}
              size="small"
              disableRipple
            >
              {ser.description}{" "}{packageServices.includes(ser.code) ? <Icon fontSize="medium">close</Icon> : <Icon fontSize="medium">add</Icon>}
            </IconButton>
          </div>
        ))}
      </div>
    );
  }

  function selectDuration() {
    return (
    <div style={{ marginTop: "20px", fontSize: "18px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {t("packages.duration")}:{" "}
        <MDInput
          type="number"
          label={t("packages.years")}
          fullWidth
          defaultValue={duration.years}
          onChange={(e) => {
            const newDuration = { ...duration };
            newDuration.years = e.target.value;
            setDuration(newDuration);
          }}
          style={{ marginBottom: "20px", marginTop: "20px", maxWidth: "100px", marginLeft: "10px" }}
        />
        <MDInput
          type="number"
          label={t("packages.months")}
          fullWidth
          defaultValue={duration.months}
          onChange={(e) => {
            const newDuration = { ...duration };
            newDuration.months = e.target.value;
            setDuration(newDuration);
          }}
          style={{ marginBottom: "20px", marginTop: "20px", maxWidth: "100px" }}
        />
        <MDInput
          type="number"
          label={t("packages.days")}
          fullWidth
          defaultValue={duration.days}
          onChange={(e) => {
            const newDuration = { ...duration };
            newDuration.days = e.target.value;
            setDuration(newDuration);
          }}
          style={{ marginBottom: "20px", marginTop: "20px", maxWidth: "100px" }}
        />
      </div>
    );
  }

  function getCredentialsLimit() {
    return (
      <div>
        {credentialsLimit.map((cl, index) => (
            <div key={`credential-limit-${cl.type}-${index.toString()}`} style={{ fontSize: "14px", alignItems: "center", display: "flex", justifyContent: "center" }}>
              {cl.type}: {" "}
              <MDInput
                type="input"
                label={t("packages.limit")}
                fullWidth
                defaultValue={cl.limit}
                onChange={(e) => {
                  const newCredentialsLimit = [].concat(credentialsLimit);
                  newCredentialsLimit[index].limit = Number(e.target.value);
                  setCredentialsLimit(newCredentialsLimit);
                }}
                style={{ marginBottom: "10px", marginTop: "0px", maxWidth: "100px" }}
              />
            </div>
          )
        )}
      </div>
    );
  }

  return (
    <SweetAlert
        style={{ display: "block" }}
        title={t("packages.add_package")}
        onConfirm={() => {
            const newServices = packageServices.map((ser) => ({ code: ser, duration }))
            const newPackage = {
              name,
              code: name,
              description,
              limits: credentialsLimit,
              services: newServices,
              enabled: true,
            }
            addElement(newPackage);
            // closeFunction();
        }}
        confirmBtnText={t("save")}
        showCancel
        cancelBtnCssClass="popupCloseButton"
        cancelBtnStyle={{
          boxShadow: "none",
        }}
        cancelBtnText={t("close")}
        onCancel={() => { closeFunction(); }}
        confirmBtnCssClass="popupCloseButton"
        confirmBtnStyle={{
        boxShadow: "none",
        }}
    >
        {getInput(t("packages.name"), setName, name)}
        {getInput(t("packages.description"), setDescription, description)}
        <div style={{ marginBottom: "5px", fontSize: "18px" }}>{t("packages.services")}:</div>
        {getServicesSelection()}
        {selectDuration()}
        {getCredentialsLimit()}
    </SweetAlert>
  );
}

// Typechecking props for the packageAddition
packageAddition.propTypes = {
  availableServices: PropTypes.arrayOf(PropTypes.any).isRequired,
  addElement: PropTypes.func.isRequired,
  closeFunction: PropTypes.func.isRequired,
};

packageAddition.defaultProps = {
  label: "",
};

export default packageAddition;
