const dataBreach = {
  en: {
    translation: {
      date: "Date",
      incident_date: "Incident date",
      published_date: "Published on",
      threat_actor_name: "Threat actor",
      description: "Description",
      victim_nation: "Nation",
      attack_type: "Attack type",
      total_records: "Total records",
      records_found: "Records found",
      columns: "Involved data",
      record_count: "Record count",
      results: "Results",
      details: "Details",
      name: "Name",
      intelligence: "Intelligence",
      page_description: "Your company data appeared in a data leak outside your perimeter.",
      back_to_results: "Go back to your results",
      attacks_found: "Records found",
      managed_breach: "Managed breach",
      manage: "Mark as managed",
      managed: "You have already seen this",
      not_managed_yet: "You have not managed this yet",
      labels: {
        full_name: "Name",
        name: "Name",
        surname: "Family name",
        ip: "IP",
        url: "URL",
        city: "City",
        email: "Email",
        username: "Username",
        password: "Password",
        password_hash: "Password hash",
        salt: "Password salt",
        phone: "Phone number",
        address: "Address",
        document_code: "ID number",
        identity_code: "TAX ID",
        company_code: "VAT number",
        payment_card_number: "Payment card number",
        payment_card_number_hash: "Payment card number hash",
        payment_card_expiration: "Payment card expiration",
        payment_card_cvv: "Payment card CVV",
        birth_date: "Birth date",
        birth_place: "Birth place",
        gender: "Gender",
        family_status: "Family status",
        religion: "Religion",
        document_issue_date: "ID issue date",
        document_expiry_date: "ID expiration date",
        document_issuer: "ID issuer",
        social_url: "Social network",
        job_title: "Job title",
        job_address: "Job address",
      },
      whatHappened_title: "What happened?",
      whatHappened_body:
        "Some credentials or personal information we were monitoring for you have appeared in a Data Breach, a database of credentials and information that ended up online following a breach of a service you were subscribed to. This could jeopardize your online security and privacy.",
    },
  },

  it: {
    translation: {
      date: "Data",
      incident_date: "Data incidente",
      published_date: "Data pubblicazione",
      threat_actor_name: "Threat Actor",
      description: "Descrizione",
      victim_nation: "Nazione",
      attack_type: "Tipo di attacco",
      total_records: "Totale registrazioni",
      records_found: "Registrazioni trovate",
      columns: "Dati coinvolti",
      record_count: "Conteggio record",
      results: "Risultati",
      details: "Dettagli",
      name: "Nome",
      intelligence: "Intelligence",
      page_description:
        "I dati relativi alla vostra azienda comparsi all’interno di una fuga di dati all’esterno del vostro perimetro.",
      back_to_results: "Torna ai tuoi risultati",
      attacks_found: "Record trovati",
      managed_breach: "Databreach gestito",
      manage: "Segna come gestito",
      not_managed_yet: "Non lo hai ancora gestito",
      managed: "Già visto",
      labels: {
        full_name: "Nome",
        name: "Nome",
        surname: "Cognome",
        ip: "IP",
        url: "URL",
        city: "Città",
        email: "Email",
        username: "Username",
        password: "Password",
        password_hash: "Password hash",
        salt: "Password salt",
        phone: "Numero di telefono",
        address: "Indirizzo",
        document_code: "Documento d'identità",
        identity_code: "Codice fiscale",
        company_code: "Partita IVA",
        payment_card_number: "Numero carta di pagamento",
        payment_card_number_hash: "Hash carta di pagamento",
        payment_card_expiration: "Scadenza carta di pagamento",
        payment_card_cvv: "CVV carta di pagamento",
        birth_date: "Data di nascita",
        birth_place: "Luogo di nascita",
        gender: "Genere",
        family_status: "Stato di famiglia",
        religion: "Religione",
        document_issue_date: "Data emissione documento",
        document_expiry_date: "Data scadenza documento",
        document_issuer: "Documento emesso da",
        social_url: "Social network",
        job_title: "Titolo",
        job_address: "Indirizzo aziendale",
      },
      whatHappened_title: "Cosa succede?",
      whatHappened_body:
        "Alcune credenziali o informazioni personali che stavamo monitorando per te sono comparse all'interno di un Data Breach, un database di credenziali ed informazioni finito online in seguito alla violazione di un servizio a cui eri iscritto. Questo potrebbe mettere a rischio la tua sicurezza online e la tua privacy.",
    },
  },
};

export default dataBreach;
