import PropTypes from "prop-types";
import Intercom from "@intercom/messenger-js-sdk";

const INTERCOM_APP_ID = "tj2434au";

function IntercomComponent({ user, pro }) {
  if (process.env.REACT_APP_ENABLE_INTERCOM === "true") {
    Intercom({
      app_id: INTERCOM_APP_ID,
      region: "eu",
      name: user?.name || undefined,
      email: user?.email || undefined,
      user_email: user?.email || undefined,
      created_at: user?.createdAt
        ? Math.floor(new Date(user.createdAt).getTime() / 1000)
        : undefined,
      user_hash: user?.intercomUserCode?.signature || undefined,
      PRO: pro,
      defenda_platform: user.hasMobile ? "protecta-web" : "dashboard",
    });
  }

  return <></>;
}

// Typechecking props for the StatisticsCounter
IntercomComponent.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  pro: PropTypes.bool,
};

IntercomComponent.defaultProps = {
  pro: false,
};

export default IntercomComponent;
