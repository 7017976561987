import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function ShowServiceDetail({ services }) {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <div>
      <div>
        <IconButton
          onClick={() => {
            setOpen(!open);
          }}
          style={{ marginRight: "15px", paddingLeft: "0", color: "#344767", fontSize: "16px" }}
          size="small"
          disableRipple
        >
          {t("user.show_service_detail")}{" "}
          {open ? (
            <ArrowDropUpIcon style={{ marginLeft: "10px" }} fontSize="medium" />
          ) : (
            <ArrowDropDownIcon style={{ marginLeft: "10px" }} fontSize="medium" />
          )}
        </IconButton>
      </div>
      {open ? (
        <div>
          {services.map((el, index) => (
            <div key={`service-detail-${el.code}-${index.toString()}`}>
              {t("user.service")}: <span style={{ fontWeight: "bold" }}>{el.code}</span> -{" "}
              {t("user.expires_on")}:{" "}
              <span style={{ fontWeight: "bold" }}>
                {moment(el.expiresOn).format("DD/MM/YYYY")}
              </span>{" "}
              {moment(el.expiresOn).isBefore(moment()) ? (
                <span style={{ color: "var(--red-color)", fontWeight: "bold" }}>
                  ({t("user.expired")})
                </span>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

ShowServiceDetail.propTypes = {
  services: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ShowServiceDetail;
