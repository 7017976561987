import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

// External components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Translation
import { useTranslation } from "react-i18next";

// APIs
import getDarkWebThreat from "api/darkweb/getDarkWebThreat";

const DarkWebDetails = () => {
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();

  const getData = async () => {
    try {
      const response = await getDarkWebThreat(id);
      setData(response);
    } catch (e) {
      console.log("error --->", e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Title */}
      <h2 className="page-title" style={{ paddingBottom: "30px" }}>
        {data && data.title}
      </h2>

      {/* Box */}
      <div className="boxes-border-radius" style={{ background: "white", padding: "15px" }}>
        {data ? (
          <>
            <dl
              style={{
                fontSize: "16px",
                columnCount: 2,
                columnGap: "20px",
              }}
            >
              <dt style={{ fontWeight: "600" }}>{t("darkWeb.threat_actor_name")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.threatActor?.name || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("darkWeb.date")}</dt>
              <dd style={{ marginBottom: "15px" }}>{moment(data.date).format("DD/MM/YYYY")}</dd>

              <dt style={{ fontWeight: "600" }}>{t("darkWeb.victim")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.victim?.name || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("darkWeb.victim_nation")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.victim?.country || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("darkWeb.victim_industry")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.victim?.industry || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("darkWeb.victim_url")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.victim?.url || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("darkWeb.attack_type")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.incidentType || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("darkWeb.source_location")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.source?.location || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("darkWeb.source_url")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.source?.url || "N/A"}</dd>

              <dt style={{ fontWeight: "600" }}>{t("darkWeb.description")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.description}</dd>

              <dt style={{ fontWeight: "600" }}>{t("darkWeb.intelligence")}</dt>
              <dd style={{ marginBottom: "15px" }}>{data.intelligence}</dd>

              <br />
              <br />
            </dl>
            <dt style={{ fontWeight: "600" }}>{t("darkWeb.screenshot")}</dt>
            <img src={data.screenshotUrl} alt="screenshot" style={{ maxWidth: "900px" }} />
          </>
        ) : null}
      </div>
    </DashboardLayout>
  );
};

export default DarkWebDetails;
